import React from "react";
import PropTypes from "prop-types";

import { addWithoutAdsToTemplates } from "../get-collection-template";
import { LazyCollection } from "@quintype/components";
import { GA, WebEngage, DeepBI, PGAD } from "../../integrations";
import styles from "./webstories.m.css";
import WCMatchList from "../rows/wc-match-list";
import WCPointTable from "../rows/wc-point-table";
import YoutubeVideoGallery from "../pages/story/YoutubeReels";
const newTemplates = addWithoutAdsToTemplates("home");
function getAdsWrappedCollectionTemplate(designTemplate) {
  return newTemplates[designTemplate] || newTemplates.defaultTemplate;
}

export class WorldcupPage extends React.Component {
  constructor() {
    super();
    this.state = { filter: null, currentIndex: 0, action: false };
    this.iframeWidgetsRef = React.createRef();
    this.pointtableWidgetsRef = React.createRef();
    this.StoryCollectionWidgetsRef = React.createRef();
  }
  componentDidMount() {
    GA.registerPageView(this.props.pageType, {});
    WebEngage.registerPageView();
    DeepBI.registerPageView({ collection: this.props });
    PGAD.loadScript();
  }

  handleFilter = (slug, index) => {
    this.setState({ filter: slug, currentIndex: index, action: false });
    setTimeout(() => this.setState({ action: true }), 500);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  handleFilter = slug => {
    this.setState({ filter: slug });

    setTimeout(() => {
      if (slug === "Play Quiz" && this.iframeWidgetsRef.current) {
        const iframeWidgetsTop = this.iframeWidgetsRef.current.offsetTop;
        window.scrollTo({
          top: iframeWidgetsTop,
          behavior: "smooth"
        });
      } else if (slug === "Points Table" && this.pointtableWidgetsRef.current) {
        const pointtableWidgetsTop = this.pointtableWidgetsRef.current.offsetTop;
        window.scrollTo({
          top: pointtableWidgetsTop,
          behavior: "smooth"
        });
      } else if (slug === "Worldcup Updates" && this.StoryCollectionWidgetsRef.current) {
        const StoryCollectionWidgetsTop = this.StoryCollectionWidgetsRef.current.offsetTop;
        window.scrollTo({
          top: StoryCollectionWidgetsTop,
          behavior: "smooth"
        });
      }
    }, 500);
  };

  handleSharePage = () => {
    if (navigator.share) {
      const sharedText =
        "🏏 கிரிக்கெட்டில் கில்லியா நீங்க? `சிக்ஸர் அடி... ஐபோன் ரெடி!' Quiz-ல் கலந்துகொண்டு ஐபோன் 13 பரிசா வெல்லுங்க! 🏆 \n \n Click here to Participate:";
      navigator
        .share({
          title:
            "🏏 கிரிக்கெட்டில் கில்லியா நீங்க? `சிக்ஸர் அடி... ஐபோன் ரெடி!' Quiz-ல் கலந்துகொண்டு ஐபோன் 13 பரிசா வெல்லுங்க! 🏆",
          text: sharedText,
          url: window.location.href
        })
        .then(() => {
          console.log("Successfully shared");
          this.hanldeAction("more share");
        })
        .catch(error => {
          console.error("Something went wrong sharing the blog", error);
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        <div
          className={`main-page styles-m__base__2vtxy js-story-main-container ${`${styles["base-class"]} ${
            styles["worldcup-page"]
          }`}`}
        >
          <div className="page-content infinity-page">
            <div className={styles["cricekt-header"]}>
              <ul>
                <li onClick={() => this.handleFilter("Play Quiz")}>Play Quiz</li>
                <li onClick={() => this.handleFilter("Points Table")}>Points Table</li>
                <li onClick={() => this.handleFilter("Worldcup Updates")}>Worldcup Updates</li>
              </ul>
            </div>
            <div className={`container ${styles["story-grid"]}`}>
              <div className={styles["banner-sec"]}>
                <div className={styles["banner-logo-sec"]}>
                  <div className={styles["sathya-logo"]}>
                    <a
                      href="https://www.sathya.store/?utm_source=Eywa&amp;utm_medium=QR_Code&amp;utm_campaign=Sathya_Website_Vikatan_Ad"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <img
                        src="https://media.vikatan.com/vikatan/2023-10/7996ea79-c41f-4a07-92d6-94771d0baa12/sathya_logo_large.png?format=webp&w=200&dpr=1.0"
                        alt=""
                      />
                    </a>
                  </div>
                  <div className={styles["sporta-logo"]}>
                    <img
                      src="https://media.vikatan.com/vikatan/2023-10/aa815208-4e9c-4aef-8974-1d7d6646cc2e/sports_vikatan.png?format=webp&w=120&dpr=1.0"
                      alt=""
                    />
                  </div>
                </div>
                <div className={styles["presents"]}>Presents</div>
              </div>
              <div>
                <div className={styles["banner-bottom-sec"]}>
                  <div>
                    <img
                      src="https://media.vikatan.com/vikatan/2023-10/5c0a9dff-fe71-45ba-96ac-fad520814366/iphone_ready.png?format=webp&w=400&dpr=1.0"
                      alt=""
                    />
                    <h2>
                      Participate in Quiz and <span>Win iPhone 13*</span>
                    </h2>
                  </div>
                  <img
                    src="https://media.vikatan.com/vikatan/2023-11/a7ff1dab-4d61-4644-84df-7dba5de0b600/1st_price_iphone13.png?format=webp&w=400&dpr=1.0"
                    alt=""
                  />
                </div>
                <div className={styles["banner-bottom-prices"]}>
                  <div>
                    <img
                      src="https://media.vikatan.com/vikatan/2023-11/050492e2-97d9-4f68-bb2f-8460a0ea9f65/2nd_price_LG_tv_new.png?format=webp&w=400&dpr=1.0"
                      alt=""
                    />
                  </div>
                  <div>
                    <img
                      src="https://media.vikatan.com/vikatan/2023-11/f0cb30d3-f667-45e6-8757-8db1b10e188a/3rd_price_home_theater_new.png?format=webp&w=400&dpr=1.0"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              {/* <div className={styles["page-share-sec"]} onClick={this.handleSharePage}>
                <div className={styles["page-share"]}>
                  <Icon type="share-alt" className={styles["share-icon"]} />
                  <span className={styles["share-text"]}>Share</span>
                </div>
              </div> */}
              <div className={styles["iframe-widgets"]} ref={this.iframeWidgetsRef}>
                <div className={styles["thanks-msg"]}>
                  {/* <h2>Thank you for participating. </h2>
                  <h3>Stay tuned and bring your enthusiasm for a chance to win a prize in our upcoming contest!</h3> */}
                  <div className={styles["iframe-widgets"]}>
                    <h2 className={styles["winners-title"]}>The Winners are</h2>
                    <div className={styles["winners-list"]}>
                      <div>
                        <img src="https://media.vikatan.com/vikatan/2023-12/88ad5a27-5b42-43a3-841a-a7f7ed47e5da/winner_1.png?format=webp&w=200&dpr=1.0" />
                        <h3>BK Anujith</h3>
                        <p className={styles["winners-dist"]}>Nagercoil</p>
                      </div>
                      <div>
                        <img src="https://media.vikatan.com/vikatan/2023-12/b5f6e493-69a1-47bd-9f6c-97052f4422c6/winner_2.png?format=webp&w=200&dpr=1.0" />
                        <h3>K. Jaguriya</h3>
                        <p className={styles["winners-dist"]}>Chennai</p>
                      </div>
                      <div>
                        <img src="https://media.vikatan.com/vikatan/2023-12/2a609981-4103-413d-ab22-bb3fb68656a9/winner_3.png?format=webp&w=200&dpr=1.0" />
                        <h3>S. Karthickeyan</h3>
                        <p className={styles["winners-dist"]}>Chennai</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <WCMatchList title="Live Match" titleClassName="white-theme" />
              <div ref={this.pointtableWidgetsRef}>
                <WCPointTable titleClassName="white-theme" />
              </div>

              <div className={styles["main-content"]} ref={this.StoryCollectionWidgetsRef}>
                <LazyCollection
                  collection={this.props.data.collection}
                  config={this.props.config}
                  collectionTemplates={getAdsWrappedCollectionTemplate}
                  lazyAfter={1}
                  accentColor={"#2f81cd"}
                  pageType={"cricket"}
                />
              </div>

              <YoutubeVideoGallery title="Worldcup Reels" titleClassName="white-theme" />

              <div className={styles["terms-wrapper"]}>
                <div className="container">
                  <div className={styles["social-wrapper"]}>
                    <div className={styles["social-media-wrap"]}>
                      <h3>Follow Sathya: </h3>
                      <div>
                        <a href="https://www.facebook.com/sathya.retail" rel="noopener noreferrer" target="_blank">
                          <img
                            src="https://media.vikatan.com/vikatan/2023-10/b6afd202-8e8d-44bc-83ec-08a35444a801/sathya_facebook.png"
                            alt="sathya Facebook"
                          />
                        </a>
                      </div>
                      <div>
                        <a href="https://www.instagram.com/sathya.retail/" rel="noopener noreferrer" target="_blank">
                          <img
                            src="https://media.vikatan.com/vikatan/2023-10/a7f8295d-043b-42ef-82b4-18baf8ab07df/sathya_insta.png"
                            alt="sathya Instagram"
                          />
                        </a>
                      </div>
                      <div>
                        <a
                          href="https://adtarbo.eywamedia.com/qr/scan?c=6529256630383"
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <img
                            src="https://media.vikatan.com/vikatan/2023-10/8c02beb1-ca9e-4402-99da-66ab7910b300/sathya_whatsapp.png"
                            alt="sathya Whatsapp"
                          />
                        </a>
                      </div>
                    </div>
                    <div className={styles["social-media-wrap"]}>
                      <h3>Follow Vikatan: </h3>
                      <div>
                        <a href="https://www.facebook.com/SportsVikatan/" rel="noopener noreferrer" target="_blank">
                          <img
                            src="https://media.vikatan.com/vikatan/2023-10/b6afd202-8e8d-44bc-83ec-08a35444a801/sathya_facebook.png"
                            alt="Vikatan Facebook"
                          />
                        </a>
                      </div>
                      <div>
                        <a href="https://www.instagram.com/sportsvikatan/" rel="noopener noreferrer" target="_blank">
                          <img
                            src="https://media.vikatan.com/vikatan/2023-10/a7f8295d-043b-42ef-82b4-18baf8ab07df/sathya_insta.png"
                            alt="Vikatan Instagram"
                          />
                        </a>
                      </div>
                      <div>
                        <a href="https://twitter.com/sportsvikatan" rel="noopener noreferrer" target="_blank">
                          <img
                            src="https://media.vikatan.com/vikatan/2023-10/3fe8af36-df1d-4ad1-a67f-1f7f1875c26b/twitter_sathya.png"
                            alt="Vikatan Twitter"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className={styles["terms-points"]}>
                    <h2>
                      <a
                        href="https://www.vikatan.com/cricket-quiz-terms-and-conditions"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <span>*</span> Terms and Conditions
                      </a>
                    </h2>
                    <div className={styles["terms-list"]}>
                      <h2>Disclaimer:</h2>
                      <p>
                        1. By participating in the &quot;Sixer Adi, iPhone Ready&quot; (&quot;Contest&quot;), you
                        acknowledge and agree to abide by the following Terms and Conditions. Vikatan reserves the right
                        to modify or update these terms at any time without prior notice.{" "}
                      </p>
                      <p>
                        2. The Contest is organized solely for entertainment and educational purposes. It is not
                        associated with, endorsed by, or affiliated with any specific sports organization, team, or
                        governing body.
                      </p>
                      <p>
                        {" "}
                        3. By participating in this Contest, you agree to be bound by the following terms and
                        conditions. If you do not agree with any part of these terms, please do not participate.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div />
        </div>
      </React.Fragment>
    );
  }
}

WorldcupPage.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.object,
    magazine: PropTypes.array
  }),
  pageType: PropTypes.string,
  config: PropTypes.shape({
    disableAds: PropTypes.bool,
    contentInsightsId: PropTypes.string
  })
};
