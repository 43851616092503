import React, { useEffect, useState } from "react";
import MobileLogin from "../../rows/header/user-login/login-mobile";
import styles from "./style.m.css";
import get from "lodash/get";

const VikatanLoginPage = () => {
  const params = new URLSearchParams(global && global.location && global.location.search);
  const redirectUrl = params.get("redirect_url") || "https://www.vikatan.com/";
  const [IspageDisplay, setIspageDisplay] = useState(true);

  useEffect(() => {
    global.userPromise.catch(() => ({})).then(user => {
      const loggedOut = get(user, ["loggedOut"], true);

      if (!loggedOut) {
        if (redirectUrl) {
          global.location.href = redirectUrl;
        }
      }
    });
  }, []);

  const handleHideLoginPage = () => {
    if (IspageDisplay) {
      setIspageDisplay(false);
    }
  };

  return (
    <React.Fragment>
      {IspageDisplay && (
        <div className={`${styles["login-page"]} container`}>
          <MobileLogin Isloginpage={true} onHideLogin={handleHideLoginPage} />
        </div>
      )}

      {!IspageDisplay && (
        <div className={styles["login-success"]}>
          <div className={styles["login-success-img"]}>
            <img src="https://gumlet.assettype.com/vikatan/2024-11-19/olbf3a52/login-success.png" alt="" />
          </div>
          Login Successful!
        </div>
      )}
    </React.Fragment>
  );
};

export { VikatanLoginPage };
