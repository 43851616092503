import React from "react";
import PropTypes from "prop-types";
import { get, chunk } from "lodash";
import { wrapCollectionLayout, LoadMoreCollectionStories } from "@quintype/components";
import BreadCrumb from "../story/story-templates/shared/breadcrumbs";
import { WebEngage, GA, DeepBI, EVOLOK, PGAD } from "../../../integrations";
import FiveStoriesOneAdBundle from "../../rows/five-stories-one-ad-bundle";
import { LoadMoreButton } from "../../atoms/read-more-link";
import { PAGE_TYPE } from "../../../constants";
import styles from "./styles.m.css";
import { VikatanCollectionResponsive, VikatanSticky, VikatanResponsiveContainer } from "../../rows/ad-free";
import BundleSixStory6s from "../../rows/bundle-six-stories";
const LOAD_MORE_TEXT = "மேலும் படிக்க";

const wrapForLoadMore = (Component, numberOfStoriesItCanAccomodate) => {
  const WrapperForLoadMore = ({ stories, collection, onLoadMore, noMoreStories, loading }) => {
    return (
      <React.Fragment>
        {chunk(stories.slice(4), numberOfStoriesItCanAccomodate).map((stories, index) => {
          const items = stories.map(story => {
            return { id: story.id, type: "story", story };
          });
          return <Component key={index} collection={{ ...collection, items }} />;
        })}
        {!noMoreStories && (
          <div className={styles["load-more-button-wrapper"]}>
            <LoadMoreButton onClick={onLoadMore} disabled={loading} text={LOAD_MORE_TEXT} />
          </div>
        )}
      </React.Fragment>
    );
  };
  WrapperForLoadMore.propTypes = {
    stories: PropTypes.array,
    collection: PropTypes.object,
    onLoadMore: PropTypes.func,
    noMoreStories: PropTypes.func,
    loading: PropTypes.bool
  };
  return WrapperForLoadMore;
};

const BundlePage = ({ data, config }) => {
  const initialStories = get(data, ["collection", "items"], []).filter(items => items.type === "story");
  const WrappedFiveStoriesOneAdBundle = wrapCollectionLayout(FiveStoriesOneAdBundle);
  React.useEffect(() => {
    WebEngage.registerPageView();
    GA.registerPageView(PAGE_TYPE.BUNDLE_PAGE, { collection: data.collection });
    EVOLOK.loadScript(PAGE_TYPE.BUNDLE_PAGE, { collection: data });
    DeepBI.registerPageView({
      collection: { data: { collection: get(data, ["collection"], {}) }, pageType: PAGE_TYPE.BUNDLE_PAGE }
    });
    PGAD.loadScript();
  }, []);

  return (
    <div className={`main-page ${styles["default-section-page-container"]}`}>
      <div />
      <div className={styles["default-section-page"]}>
        <div className="container">
          <BreadCrumb config={config} collection={data.collection} />
          {data.collection.slug && data.collection.slug === "remembering-m-karunanidhi-special-collection" ? (
            <div className="Top_banner_Bundle_karunanidhi">
              <img
                src="https://media.vikatan.com/vikatan/2021-05/aff92cc4-441c-4700-a750-face767b0941/Top_banner_Bundle_karunanidhi.jpg"
                className={styles["Top_banner_Bundle_karunanidhi"]}
              />
            </div>
          ) : null}
        </div>
        <WrappedFiveStoriesOneAdBundle collection={{ ...data.collection, items: initialStories.slice(0, 10) }} />

        <div className="hidden-desktop">
          <VikatanResponsiveContainer className="ad_300_250" placement={"bundle-page"} />
        </div>

        <LoadMoreCollectionStories
          template={wrapForLoadMore(BundleSixStory6s, 19)}
          collectionSlug={data.collection.slug}
          data={Object.assign(
            {},
            { collection: data.collection },
            { stories: [...initialStories.map(({ story }) => story).slice(0, 10)] },
            { storiesPerPage: 19 }
          )}
          numStoriesToLoad={19}
          params={{ "item-type": "story" }}
        />
        <div className={styles["bundle-ad"]}>
          <VikatanCollectionResponsive
            DesktopAdtype="Vikatan2023_Desktop_SP_BTF_728x90"
            MobileAdtype="Vikatan2023_Mobile_SP_TOP_320x100"
            placement="bundle"
          />
        </div>
      </div>
      <div />
      <VikatanSticky placement="bundle" />
    </div>
  );
};

export { BundlePage };

BundlePage.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.object
  }),
  config: PropTypes.shape({})
};

export default BundlePage;
