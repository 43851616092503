import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { fetchOlympicsMedalTable24 } from "../../../api";
import styles from "./styles.m.css";

const OlympicsIndiaMedalPointsTable = ({ accentColor, titleClassName }) => {
  const [teamsData, setTeamsData] = useState([]);

  useEffect(() => {
    fetchOlympicsMedalTable24().then(data => {
      setTeamsData(data.teams);
    });
  }, []);

  return (
    <div className={`${styles["base"]} ${styles["pagetable"]}`}>
      <div className={`${styles["point-table-wrapper"]} container`}>
        <h6 className={`${styles["point-table-title"]} ${styles[titleClassName]}`}>ஒலிம்பிக்ஸ் 2024 பதக்கப்பட்டியல்</h6>
        <div className={styles["medal-table"]}>
          {teamsData.length > 0 ? (
            <table className={styles["inner-table"]}>
              <thead>
                <tr>
                  <th>Order</th>
                  <th>NOCs</th>
                  <th>
                    <img
                      src="https://media.vikatan.com/vikatan/2024-08/fbf6830f-b907-4a5f-8776-bf9101efb37d/olympics-gold.png"
                      alt="Gold"
                    />
                    <p>Gold</p>
                  </th>
                  <th>
                    <img
                      src="https://media.vikatan.com/vikatan/2024-08/a30c5ccb-7dc1-4e79-8c45-77dbfb0cd428/olympics-silver.png"
                      alt="Silver"
                    />
                    <p>Silver</p>
                  </th>
                  <th>
                    <img
                      src="https://media.vikatan.com/vikatan/2024-08/9b809ca3-1fa3-4e57-8926-5e141fac8c4f/olympics-bronze.png"
                      alt="Bronze"
                    />
                    <p>Bronze</p>
                  </th>
                  <th>
                    <img src="https://media.vikatan.com/vikatan/2024-08/7d0b1518-c990-4d87-9648-48731352217a/olympics-all.png" />
                    <p>All</p>
                  </th>
                </tr>
              </thead>
              <tbody>
                {teamsData.map((team, index) => (
                  <tr key={team.id}>
                    <td>{team.position}</td>
                    <td>{team.team_name}</td>
                    <td>{team.gold}</td>
                    <td>{team.silver}</td>
                    <td>{team.bronze}</td>
                    <td>{team.total_medals}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </div>
  );
};

OlympicsIndiaMedalPointsTable.propTypes = {
  accentColor: PropTypes.string,
  titleClassName: PropTypes.string
};

export default OlympicsIndiaMedalPointsTable;
